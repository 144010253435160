import Link from "next/link";
import DrawerWrapper from "./DrawerWrapper";
import Navbar from "./Navbar";
export default function Error({ message, trace = "No Trace" }) {
  return (
    <DrawerWrapper>
      <Navbar />
      <div className="h-screen flex flex-col justify-center items-start mx-24">
        <div role="alert" className="alert alert-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 shrink-0 stroke-current"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span>{message ?? "An error occured."}</span>
        </div>

        <div className="card bg-error mt-5 w-full shadow-sm">
          <div className="card-body">
            <h2 className="card-title text-white">Stack Trace</h2>
            <p className="text-white break-words">{trace}</p>
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-5">
          <Link href="/">
            <button className="btn btn-primary">Go Back</button>
          </Link>
        </div>
      </div>
    </DrawerWrapper>
  );
}
