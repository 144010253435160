// import Keuangan from "@/data/Solution/Finance";
// import Informasi from "@/data/Solution/Information";
// import Akademik from "@/data/Solution/Academic";
// import Kesiswaan from "@/data/Solution/Studentship";
// import Minimarket from "@/data/Payment/Minimarket";
// import VA from "@/data/Payment/VA";
import Link from "next/link";
// import Admin from "@/data/Solution/Admin";
// import Cazh from "@/data/Payment/Cazh";
// import QR from "@/data/Payment/QR";

export default function DrawerWrapper({ children }) {
  return (
    <div className="drawer">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">{children}</div>
      <div className="drawer-side z-[1000]">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>
        <ul className="menu p-4 w-80 min-h-full bg-base-100 text-base-content">
          {/* <li>
            <Link href="/">Beranda</Link>
          </li>
          <li>
            <details>
              <summary>Partner</summary>
              <ul>
                <li><Link href="/partner/sekolah">Sekolah</Link></li>
                <li><Link href="/partner/pesantren">Pesantren</Link></li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>Solusi</summary>
              <ul>
                <li>
                  <h2 className="font-semibold">Administrasi</h2>
                  <ul>
                      {Admin.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/solusi/administrasi/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Informasi</h2>
                  <ul>
                      {Informasi.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/solusi/informasi/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Keuangan</h2>
                  <ul>
                      {Keuangan.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/solusi/keuangan/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Akademik</h2>
                  <ul>
                      {Akademik.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/solusi/akademik/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Kesiswaan</h2>
                  <ul>
                      {Kesiswaan.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/solusi/kesiswaan/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>Pembayaran</summary>
              <ul>
                <li>
                  <h2 className="font-semibold">CAZH</h2>
                  <ul>
                      {Cazh.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/pembayaran/cazh/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Scan QR</h2>
                  <ul>
                      {QR.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/pembayaran/qr/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">Minimarket</h2>
                  <ul>
                      {Minimarket.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/pembayaran/minimarket/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
                <li>
                  <h2 className="font-semibold">VA Bank</h2>
                  <ul>
                      {VA.map((x, idx) => {
                        return (
                          <li key={idx}>
                            <Link href={`/pembayaran/va-bank/${x.slug}`}>{x.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </li>
              </ul>
            </details>
          </li>
          <li>
            <details>
              <summary>Tentang Kami</summary>
              <ul>
                <li><Link href="/tentang/kami">Tim CARDS</Link></li>
                <li><Link href="/tentang/mitra-bisnis">Mitra Bisnis</Link></li>
                <li><Link href="/tentang/berita">Berita</Link></li>
                <li><Link href="/tentang/kegiatan">Kegiatan</Link></li>
              </ul>
            </details>
          </li> */}
          <li>
            <Link href="/kontak">Kontak</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
