import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

export default function Pagination({
  currentPage,
  totalData,
  perPage,
  setCurrentPage,
  className,
}) {
  const totalPages = Math.ceil(totalData / perPage);

  return (
    <div className={`${className} flex justify-between`}>
      <p>
        Menampilkan <span className="font-semibold">{currentPage}</span> ke{" "}
        <span className="font-semibold">
          {totalData > perPage ? perPage : totalData}
        </span>{" "}
        dari <span className="font-semibold">{totalData}</span> hasil
      </p>

      <div className="flex gap-2">
        <button
          disabled={currentPage === 1}
          onClick={() => {
            if (currentPage === 1) return;
            setCurrentPage(currentPage - 1);
          }}
          className="btn btn-sm btn-outline border border-slate-300"
        >
          <MdKeyboardArrowLeft size={20} />
        </button>

        {Array.from({ length: totalPages }, (_, index) => {
          if (
            index === 0 ||
            index === totalPages - 1 ||
            (index >= currentPage - 2 && index <= currentPage)
          ) {
            return (
              <button
                onClick={() => setCurrentPage(index + 1)}
                key={index}
                className={`btn btn-sm btn-outline border border-slate-300 ${
                  currentPage === index + 1 ? "bg-primary text-white" : ""
                }`}
              >
                {index + 1}
              </button>
            );
          } else if (
            (index === currentPage - 3 && index > 1) ||
            (index === currentPage + 1 && index < totalPages - 2)
          ) {
            return (
              <span key={index} className="btn btn-sm btn-disabled">
                ...
              </span>
            );
          }
          return null;
        })}

        <button
          disabled={currentPage === totalPages || totalData <= perPage}
          onClick={() => {
            if (currentPage === totalPages) return;
            setCurrentPage(currentPage + 1);
          }}
          className="btn btn-sm btn-outline border border-slate-300"
        >
          <MdKeyboardArrowRight size={20} />
        </button>
      </div>
    </div>
  );
}
