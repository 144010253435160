import Link from "next/link";
import useSWR from "swr";
import clientFetch from "@/lib/ClientFetch";
import Image from "next/image";
import Error from "../Error";
import Loading from "../Loading";
import SectionMark from "../Misc/SectionMark";
import Pagination from "../Pagination";
import { useState } from "react";

const EmptyState = () => {
  return (
    <div className="flex justify-center items-center h-[450px]">
      <p className="text-dark-muted/50 text-lg">Tidak ada Artikel</p>
    </div>
  );
};

export const ArticleCard = ({ title, created_at, summary, id, banner }) => {
  return (
    <div className="card border border-slate-200 p-4 h-[450px] bg-white shadow-sm">
      <div className="relative w-full h-80">
        <Image
          src={banner}
          fill
          objectFit="cover"
          className="rounded-lg"
          alt="Shoes"
        />
      </div>
      <div className="card-body p-2">
        <h2 className="card-title">{title}</h2>
        <p className="text-dark-muted/50 text-sm truncate">{summary}</p>

        <div className="card-actions justify-between items-center">
          <div className="text-xs text-dark-muted/50">
            <span>{new Date(created_at).toDateString()}</span>
          </div>

          <div>
            <Link href={`/artikel/${id}`}>
              <button className="btn btn-primary rounded-full btn-sm">
                Baca Selengkapnya
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function Articles() {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const { data, isLoading, error } = useSWR(
    [`landing-page/articles?per_page=${perPage}&page=${currentPage}`],
    (url) => clientFetch(url, "hub")
  );

  if (error) <Error message={error.message} trace={error.stack} />;
  if (!data || isLoading) return <Loading />;

  return (
    <section className="lg:mx-24 mx-12 mt-24 mb-5">
      <div className="mb-10">
        <SectionMark center>
          <p className="lg:text-3xl text-lg uppercase tracking-wider">
            Artikel
          </p>
        </SectionMark>

        <p className="lg:text-4xl text-xl font-bold text-center">
          Update Informasi Terbaru
        </p>
      </div>

      {data.data.articles.length === 0 && <EmptyState />}

      {data.data.articles.length > 0 && (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
            {data.data.articles.map((article) => {
              return <ArticleCard key={article.id} {...article} />;
            })}
          </div>

          <Pagination
            className={"mt-5"}
            perPage={perPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalData={data.data.meta.total}
          />
        </>
      )}
    </section>
  );
}
